<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Edit Customer</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form @submit.prevent="submit">
                <CustomerFieldset v-model="formData" :disabled="loading" form-id="edit_customer_form" />
                <div class="mt-2 d-flex justify-content-end">
                    <router-link :to="{ name: 'customer-details', params: { id: customer.id }}"
                                 class="btn btn-sm btn-falcon-default">
                        Cancel
                    </router-link>
                    <button type="submit"
                            class="btn btn-sm btn-falcon-primary ml-2"
                            :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import CustomerFieldset from './CustomerFieldset.vue';
    import { updateCustomer } from '../../services/CustomerService';

    export default {
        name: 'EditCustomer',
        components: {
            CustomerFieldset,
        },
        props: {
            customer: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                formData: {
                    // Make a copy of the customer data for the user to edit
                    name: this.customer.name,
                    identifier: this.customer.identifier,
                    phone: this.customer.phone,
                    email: this.customer.email,
                    website: this.customer.website,
                },
            };
        },
        methods: {
            async submit() {
                this.loading = true;
                try {
                    const { data } = await updateCustomer(this.customer.id, this.formData);

                    this.$emit('customer-edited', data);
                    this.$router.push({ name: 'customer-details', params: { id: this.customer.id }});
                } catch (e) {
                    this.$alerts.danger('Could Not Edit Customer',
                                        'Please check your submission for errors. If the problem persists, please contact support.');
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>
